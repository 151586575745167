import { PageNavAction } from "@/types/types";
import { Callback } from "helix-vue-components";
import { Component, Vue } from "vue-property-decorator";
import { Action } from "vuex-class";
import Template from "./Metrc.template.vue";

@Component({
  mixins: [Template]
})
export default class MetrcComponent extends Vue {
  @Action("changeTitle", { namespace: "MegaMenuModule" })
  public changeTitleAction!: Callback;
  @Action("changeIcon", { namespace: "MegaMenuModule" })
  public changeIcon!: Callback;
  @Action("setPageNav", { namespace: "PageNavModule" })
  public setPageNav!: PageNavAction;
  constructor() {
    super();
    this.setPageNav(null);
    this.changeTitleAction("metrc.title");
    this.changeIcon(require("@/assets/images/icon_primary_menu_metrc@2x.png"));
  }
}
